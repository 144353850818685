.update-btn {
  width: 22rem;
  height: 3.6rem;
  outline: none;
  border-radius: 0.2rem;
  background: var(--color-gs20);
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center; }
  .update-btn > div {
    font-size: 1.7rem;
    color: var(--color-gs90); }
  .update-btn:focus {
    background: var(--color-purple); }
    .update-btn:focus > div {
      color: var(--color-gs00); }

@media only screen and (orientation: landscape) {
  .update-btn {
    width: 30rem; } }
