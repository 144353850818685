/**
 * Gaia Tabs
 *
 * Tabs allow users to navigate between
 * multiple views within a single screen.
 */
gaia-tabs {
  display: flex;
  position: relative;
  bottom: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 0;
  background: var(--color-gs00); }

/**
 * [position="top"]
 */
gaia-tabs[position='top'] {
  top: 0;
  bottom: auto;
  height: 3rem; }

/**
 * [skin="dark"]
 */
gaia-tabs[skin='dark'] {
  border-color: rgba(189, 189, 189, 0.1); }

/** Tab
 ---------------------------------------------------------*/
gaia-tabs > * {
  box-sizing: content-box;
  position: relative;
  margin: 0;
  padding: 0 0.5rem 0 1rem;
  height: 3rem;
  border: 0;
  flex: 1 1 0;
  line-height: 3rem;
  text-align: center;
  font-family: sans-serif;
  text-decoration: none;
  color: var(--color-gs45);
  background-color: transparent;
  cursor: pointer;
  list-style: none;
  white-space: nowrap; }

gaia-tabs a {
  text-decoration: none;
  display: block; }

/**
 * [position="top"]
 */
gaia-tabs[position='top'] > * {
  height: 3rem; }

/**
 * [skin="dark"]
 */
gaia-tabs[skin='dark'] > * {
  background-color: #000;
  color: #fff; }

/**
 * :active
 */
gaia-tabs > :active {
  border-radius: 0;
  background-color: #b2f2ff; }

/**
 * .selected
 */
gaia-tabs > .selected {
  color: var(--color-gs90);
  font-weight: 700; }

/**
 * .selected &
 * [skin="dark"]
 */
gaia-tabs[skin='dark'] > .selected {
  color: #00aacc; }

/**
 * [disabled]
 */
gaia-tabs > [disabled] {
  color: #333;
  opacity: 0.25;
  pointer-events: none; }

/**
 * [skin="dark"] &
 * [disabled]
 */
gaia-tabs[skin='dark'] > [disabled] {
  color: rgba(255, 255, 255, 0.4);
  opacity: 1; }

/** Tab Bottom Border
 ---------------------------------------------------------*/
gaia-tabs > .selected:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  border-bottom: 0.3rem solid #00aacc; }

/**
 * [underline="child"]
 * For this option we need a child element
 * inside tabs, e.g:
 * <gaia-tabs>
 *   <button><span>Text</span></button>
 * </gaia-tabs>
 */
gaia-tabs[underline='child'] > .selected:after {
  display: none; }

gaia-tabs[underline='child'] > .selected > :after {
  content: '';
  position: absolute;
  left: -0.5rem;
  bottom: 0;
  width: calc(100% + 1rem);
  border-bottom: 0.3rem solid var(--highlight-color); }

gaia-tabs[underline='child'] > .selected > *::after {
  margin-bottom: 0.2rem; }

gaia-tabs[underline='child'] > .selected > * {
  position: relative;
  display: inline-block;
  height: 100%; }
