/* Generated by grunt-webfont */
/* Based on https://github.com/endtwist/fontcustom/blob/master/lib/fontcustom/templates/fontcustom.css */
@font-face {
  font-family: 'gaia-icons';
  src: url(a64eef7a482f64f0ed5c9dbcc61c37aa.ttf) format("truetype");
  font-weight: 500;
  font-style: normal;
  font-variant-numeric: nominal; }

[data-icon]:before,
.ligature-icons {
  font-family: 'gaia-icons';
  content: attr(data-icon);
  display: inline-block;
  font-weight: 500;
  font-style: normal;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased; }
