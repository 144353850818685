.PagePanel {
  margin-top: 2.6rem;
  width: 100%;
  z-index: 1000;
  display: flex;
  flex-flow: column;
  height: 26.4rem;
  justify-content: flex-start; }
  .PagePanel .desc {
    outline: none;
    flex-grow: 1;
    margin: 0.5rem 0rem;
    overflow-y: auto; }
  .PagePanel .Page-desc {
    height: 100%;
    color: #000000;
    word-wrap: break-word;
    font-size: 1.4rem;
    outline: none; }
    .PagePanel .Page-desc p {
      margin: 0 1.2rem 0 1.2rem; }
    .PagePanel .Page-desc .website-info {
      margin: 0.5rem 1.2rem 0.2rem 1.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: var(--color-gs90, #323232); }
      .PagePanel .Page-desc .website-info .website-url {
        width: calc(100% - 2.4rem);
        height: 1.4rem;
        padding-left: 1.8rem;
        background: url(1ace931adc45b8e52003889b908c70bc.png) 0 no-repeat; }
      .PagePanel .Page-desc .website-info h4 {
        margin: 1rem 0 0 0;
        padding: 0;
        font-weight: bold; }
  .PagePanel .update-page-desc {
    height: 100%;
    text-align: center;
    padding: 4rem 1.5rem 0;
    box-sizing: border-box; }
    .PagePanel .update-page-desc h2 {
      margin: 0; }
    .PagePanel .update-page-desc p {
      margin: 1rem 0; }
  .PagePanel .update-btn-wrapper {
    height: 5.6rem;
    justify-content: center;
    align-items: center;
    display: flex; }
    .PagePanel .update-btn-wrapper .btn {
      width: 22rem;
      height: 3.6rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 2px;
      background: var(--color-purple);
      color: var(--color-gs00);
      font-size: 1.7rem; }

.enableBN {
  display: block;
  background: #ffffff;
  width: 20rem;
  height: 1.7rem;
  border-radius: 4.4rem;
  box-shadow: 0rem 0.1rem 0.3rem 0rem rgba(0, 0, 0, 0.3);
  text-align: center;
  padding: 0.4rem 0rem;
  font-size: 1.4rem;
  position: absolute;
  left: 2rem;
  bottom: 3.7rem; }

.dialog-container .dialog {
  bottom: var(--softkeybar-height) !important; }
  .dialog-container .dialog > .header {
    text-align: center; }
  .dialog-container .dialog > .content {
    font-size: 1.4rem;
    width: calc(100% - 2rem); }
