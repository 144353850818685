div.SettingsPanel {
  margin-top: var(--statusbar-height);
  width: 100%;
  height: calc(100% - var(--softkeybar-height));
  overflow: hidden;
  background-color: var(--color-gs00); }

h1.header {
  height: 8.7vh;
  background-color: var(--header-purple-background);
  text-align: center;
  font-size: 1.7rem;
  padding: 0 1rem;
  color: var(--color-gs00);
  margin: 0; }
  h1.header.large {
    font-size: 2.2rem; }

.item-list {
  overflow: hidden; }
  .item-list .item {
    height: 6rem;
    width: 100%;
    color: var(--color-gs90);
    font-size: 1.7rem;
    outline: none;
    background-color: var(--color-gs00); }
    .item-list .item .content {
      padding-top: 0.7rem;
      height: 4.6rem; }
      .item-list .item .content p {
        margin: 0 1rem; }
      .item-list .item .content p.single-line-item {
        margin: 1rem; }
      .item-list .item .content.large {
        font-size: 2.2rem; }
      .item-list .item .content span {
        margin: 0 1rem;
        font-size: 1.4rem;
        display: inline-block;
        text-overflow: ellipsis;
        word-wrap: break-word;
        overflow: hidden;
        max-height: 3.8em; }
        .item-list .item .content span.large {
          font-size: 1.8rem; }
    .item-list .item:focus {
      background-color: var(--color-purple);
      color: var(--color-gs00); }

.hidden-select {
  position: fixed;
  top: -100%; }
