.SearchPanel {
  margin-top: var(--statusbar-height);
  width: 100%;
  height: calc(100% - var(--statusbar-height)); }

.SearchBar {
  padding: 0;
  margin: 0; }
  .SearchBar > ul {
    list-style: none;
    display: block;
    margin-block-end: 0;
    padding: 0.5rem 1rem; }
    .SearchBar > ul > li {
      display: inline; }
      .SearchBar > ul > li > input {
        width: 100%;
        height: 2.6rem;
        font-size: 1.7rem;
        padding-inline-start: 1rem;
        box-sizing: border-box;
        direction: inherit; }

.SearchBar.active {
  background-color: var(--color-purple); }

.SearchListBox {
  height: 23rem;
  overflow: hidden; }

.SearchMsgBox {
  margin: 1rem;
  overflow: hidden;
  height: calc(100% - 3.6rem - var(--softkeybar-height));
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; }

.Loader {
  width: 6rem;
  height: 6rem;
  background-image: url(3f5f4b3ff8df8a382aa54ce073cbc946.png);
  background-size: contain;
  background-repeat: no-repeat; }
