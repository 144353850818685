.update-brick {
  height: 6rem;
  display: flex;
  align-items: center;
  outline: 0; }
  .update-brick:focus .content .name {
    display: flex;
    flex-direction: column; }
  .update-brick .icon-wrapper {
    width: 3.2rem;
    height: 3.2rem;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 1rem;
    background-image: url(8e17949f539fdfafbcbfb7aaf3cd7e21.png); }
  .update-brick > .content {
    width: 14.6rem; }
    .update-brick > .content .name {
      font-size: 1.7rem;
      color: var(--color-gs90);
      white-space: nowrap;
      overflow: hidden;
      align-items: flex-start; }
      .update-brick > .content .name > div {
        text-overflow: ellipsis;
        overflow: hidden; }
    .update-brick > .content .size {
      font-size: 1.4rem;
      color: var(--color-gs70); }
    .update-brick > .content .progress-bar-wrapper {
      padding: 0.6rem 0; }
      .update-brick > .content .progress-bar-wrapper .progress-bar {
        background: var(--color-gs45); }
        .update-brick > .content .progress-bar-wrapper .progress-bar .active {
          background: var(--color-purple) !important; }
        .update-brick > .content .progress-bar-wrapper .progress-bar .tip {
          background: var(--color-gs00);
          width: 0.3rem; }
  .update-brick .indicator {
    padding: 0px 1rem;
    width: 3.2rem;
    height: 3.2rem;
    display: flex;
    justify-content: center;
    align-items: center; }
    .update-brick .indicator .arrow {
      font-size: 2.3rem;
      color: var(--color-gs70); }
    .update-brick .indicator .pending-icon {
      width: 3.2rem;
      height: 3.2rem;
      background-image: url(73e52351fe25d0e76daa890f9bda9649.png); }
  .update-brick:focus {
    background: var(--color-purple); }
    .update-brick:focus .content .name,
    .update-brick:focus .content .size {
      color: var(--color-gs00); }
    .update-brick:focus .indicator .arrow {
      color: var(--color-gs00); }
    .update-brick:focus .progress-bar-wrapper .progress-bar .active {
      background: var(--color-gs00) !important; }
    .update-brick:focus .progress-bar-wrapper .progress-bar .tip {
      background: var(--color-purple); }

@media only screen and (orientation: landscape) {
  .update-brick > .content {
    width: 28.6rem; } }
