.divider {
  background: var(--color-gs10);
  font-size: 1.3rem;
  height: 2.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  box-sizing: border-box; }
  .divider .title {
    color: #757575; }
  .divider .indicator {
    color: var(--color-gs00);
    border-radius: 0.9rem;
    background: var(--color-red);
    padding: 0 0.5rem; }
