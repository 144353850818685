.AppsView {
  margin-top: 2.6rem;
  width: 100%;
  overflow: hidden; }
  .AppsView > ul {
    margin: 0;
    padding: 0;
    height: 2.7rem;
    display: flex;
    align-items: stretch;
    justify-content: flex-start; }
    .AppsView > ul > li {
      flex-shrink: 0;
      list-style-type: none;
      margin-left: 0.5rem; }
      .AppsView > ul > li:first-child {
        margin-left: 1rem; }
      .AppsView > ul > li:after {
        content: '';
        display: block;
        height: 0.3rem;
        width: 100%;
        background-color: var(--color-purple, #873eff);
        border-radius: 1.5px 1.5px;
        opacity: 0;
        margin-top: -0.1rem; }
      .AppsView > ul > li.active:after {
        opacity: 1; }
      .AppsView > ul > li > span {
        padding: 0.9rem 0.5rem 0rem 0.5rem;
        text-transform: capitalize;
        color: var(--color-gs45, #aaaaaa);
        font-size: 1.4rem; }
      .AppsView > ul > li.active > span {
        font-family: 'Open Sans Semi-Bold';
        color: var(--color-gs00, #ffffff); }

.ListviewBox {
  height: 24rem;
  overflow: hidden; }

@media only screen and (orientation: landscape) {
  .ListviewBox {
    height: 16rem; } }
