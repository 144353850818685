html,
body,
#root,
#app {
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-size: 10px;
  outline: none;
  overflow: hidden;
  font-family: 'Open Sans Regular';
  background-color: var(--color-white); }

#app.bg {
  background: url(b395f82660c74d5e10b1baee6eb60841.png) 0 0 no-repeat; }
  #app.bg .skbar {
    background-color: unset; }

#app.settings {
  background-color: #ffffff; }

#app.bright {
  background-color: #ffffff; }
  #app.bright .skbar {
    background-color: #ffffff; }
    #app.bright .skbar .sk-button {
      color: var(--softkey-color); }

.content {
  width: 100%; }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }

.skbar {
  background-color: var(--color-gs00); }

.soft-key-wrapper.default .skbar {
  background-color: var(--softkeybar-background-color) !important; }

.panel.hide {
  display: none; }
  .panel.hide[data-name='page'] {
    display: block; }

.AppList h1 {
  width: 100%;
  text-align: center;
  height: 4rem;
  background-color: #696bff;
  font-size: 1.7rem;
  color: var(--color-gs00);
  margin: 0;
  padding: 4rem 0 0 0; }

.dialog-container .dialog {
  bottom: var(--softkeybar-height) !important; }
  .dialog-container .dialog > .header {
    text-transform: capitalize; }

.update-dialog {
  width: 100%;
  height: 17rem;
  background-color: #e9e9e9;
  position: fixed;
  top: calc(100% - 17rem);
  text-align: center;
  color: #000000;
  font-size: 1.2rem;
  opacity: 1;
  z-index: 1000; }
  .update-dialog.hide {
    top: 100%;
    opacity: 0; }

gaia-tabs {
  background: transparent; }
  gaia-tabs > .selected {
    color: var(--color-gs90);
    font-weight: inherit; }

.loader {
  background: url(3f5f4b3ff8df8a382aa54ce073cbc946.png) center center no-repeat;
  width: 100%;
  height: 100%;
  visibility: visible; }
