.like-modal-wrapper {
  margin-top: 6.5rem;
  position: absolute;
  left: 1.5rem;
  width: calc(100% - 3rem);
  display: flex;
  justify-content: center;
  transition: transform 0.3s ease-in-out; }

.like-toast {
  width: 9rem;
  height: 9rem;
  background-image: url(b92a2e82a8401f70a1b421c90c5960c7.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  animation: like-animation 700ms linear both; }

.unlike-toast {
  min-width: 12rem;
  border-radius: 2rem;
  background-color: var(--color-gs100);
  opacity: 80%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 7rem;
  display: flex;
  align-items: center;
  color: var(--color-gs00); }

.unlike-msg {
  font-size: 1.4rem;
  margin: 1rem 1.5rem; }

@keyframes like-animation {
  0% {
    transform: matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  5.81% {
    transform: matrix3d(1.422, 0, 0, 0, 0, 1.483, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  7.61% {
    transform: matrix3d(1.536, 0, 0, 0, 0, 1.624, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  11.41% {
    transform: matrix3d(1.738, 0, 0, 0, 0, 1.869, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  11.61% {
    transform: matrix3d(1.747, 0, 0, 0, 0, 1.88, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  15.12% {
    transform: matrix3d(1.881, 0, 0, 0, 0, 2.029, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  17.42% {
    transform: matrix3d(1.946, 0, 0, 0, 0, 2.09, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  18.92% {
    transform: matrix3d(1.98, 0, 0, 0, 0, 2.116, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  22.72% {
    transform: matrix3d(2.038, 0, 0, 0, 0, 2.142, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  23.12% {
    transform: matrix3d(2.042, 0, 0, 0, 0, 2.142, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  30.23% {
    transform: matrix3d(2.072, 0, 0, 0, 0, 2.099, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  30.33% {
    transform: matrix3d(2.072, 0, 0, 0, 0, 2.098, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  37.44% {
    transform: matrix3d(2.057, 0, 0, 0, 0, 2.033, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  44.54% {
    transform: matrix3d(2.032, 0, 0, 0, 0, 1.994, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  50.25% {
    transform: matrix3d(2.015, 0, 0, 0, 0, 1.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  51.65% {
    transform: matrix3d(2.012, 0, 0, 0, 0, 1.984, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  70.27% {
    transform: matrix3d(1.997, 0, 0, 0, 0, 1.999, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  80.28% {
    transform: matrix3d(1.998, 0, 0, 0, 0, 2.002, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); }
  100% {
    transform: matrix3d(2, 0, 0, 0, 0, 2, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1); } }
