.AppListView {
  display: block;
  padding: 0;
  outline: none;
  height: 24rem;
  /* invsible scrollable container to make sure scrollIntoView works
   while scroll bar is hidden at the same time */ }
  .AppListView > .container {
    width: 100%;
    height: 100%;
    overflow: auto;
    visibility: hidden; }
  .AppListView .list {
    margin: 0;
    outline: none; }
